<template>
  <div @click="handle">
    <div v-if="pause">
      {{ $t("handle.resume") }}
    </div>

    <div v-else>
      {{ $t("handle.pause") }}
    </div>
  </div>
</template>

<script>
import { cronJobPause } from "api/deployment";
export default {
  props: {
    pause: {
      type: Boolean
    },
    application: {
      type: String
    },
    namespace: {
      type: String
    },
    vendor: {
      type: String
    },
    cluster: {
      type: String
    },
    region: {
      type: String
    }
  },
  methods: {
    async handle() {
      this.$confirm(
        this.pause ? this.$t("resume", [this.application]) : this.$t("pause", [this.application]),
        this.$t("prompt"),
        {
          dangerouslyUseHTMLString: true,
          type: "warning"
        }
      )
        .then(() => {
          cronJobPause({
            ...{ vendor: this.vendor, region: this.region, cluster: this.cluster, namespace: this.namespace },
            name: this.application,
            pause: this.pause
          }).then(response => {
            if (response.code === 0) {
              this.$emit("update");
              this.$notify({
                title: "Success",
                message: "Success",
                type: "success"
              });
            }
          });
        })
        .catch(() => {});
    }
  }
};
</script>
