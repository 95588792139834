<template>
  <div>
    <div @click="rollback">
      {{ $t("handle.rollback") }}
    </div>

    <el-dialog
      :title="$t('handle.rollback')"
      :visible.sync="rollbackVisible"
      top="30px"
      :close-on-click-modal="false"
      :append-to-body="true"
      width="1200px"
    >
      <div v-loading="loading">
        <el-table :data="list">
          <el-table-column
            :label="$t('version')"
            sortable
            prop="metadata.annotations['deployment.kubernetes.io/revision']"
          >
            <template slot-scope="scope">
              <div class="table-name-info">
                <svg-icon icon-class="tag"></svg-icon>
                <div class="content">
                  <div class="title">
                    {{ $t("version") }}: {{ scope.row.metadata.annotations["deployment.kubernetes.io/revision"] }}
                  </div>

                  <span class="desc">
                    {{ moment(scope.row.metadata.creationTimestamp).format("YYYY-MM-DD HH:mm:ss") }}
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="ReplicaSet" prop="metadata.name"> </el-table-column>

          <el-table-column :label="$t('image')" prop="spec.template.spec.containers[0].image"> </el-table-column>

          <el-table-column>
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                plain
                icon="el-icon-position"
                @click="
                  submit(scope.row.spec.template, scope.row.metadata.annotations['deployment.kubernetes.io/revision'])
                "
                :disabled="currentRevision == scope.row.metadata.annotations['deployment.kubernetes.io/revision']"
                :loading="loadingData[scope.row.metadata.annotations['deployment.kubernetes.io/revision']]"
              >
                <span v-if="currentRevision == scope.row.metadata.annotations['deployment.kubernetes.io/revision']">
                  {{ $t("currentVersion") }}
                </span>
                <span v-else>
                  {{ $t("handle.toRollback") }}
                </span>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { rollbackList, applicationUpdate, deploymentDetail } from "api/deployment";

import moment from "moment";

export default {
  props: {
    application: {
      type: String
    },
    namespace: {
      type: String
    },
    vendor: {
      type: String
    },
    cluster: {
      type: String
    },
    region: {
      type: String
    }
  },
  data() {
    return {
      rollbackVisible: false,
      list: [],
      currentRevision: "",
      loading: true,
      loadingData: {},
      data: {}
    };
  },

  methods: {
    moment,

    async rollback() {
      this.rollbackVisible = true;
      this.loading = true;

      let response = await deploymentDetail(
        {
          application: this.application,
          ...{ vendor: this.vendor, region: this.region, cluster: this.cluster, namespace: this.namespace }
        },
        { type: this.type }
      );

      if (response.code === 0) {
        this.data = response.data;
        this.currentRevision = response.data.spec.deployment.metadata.annotations["deployment.kubernetes.io/revision"];
      }

      let rollbackRep = await rollbackList({
        ...{ vendor: this.vendor, region: this.region, cluster: this.cluster, namespace: this.namespace },
        deployment: this.application
      });

      if (rollbackRep.code === 0) {
        this.loading = false;
        this.list = rollbackRep.data.items;

        this.list.forEach(item => {
          this.$set(this.loadingData, item.metadata.annotations["deployment.kubernetes.io/revision"], false);
        });
      }
    },

    submit(template, verison) {
      this.loadingData[verison] = true;

      this.data.spec.deployment.spec.template = template;
      this.$set(this.data.spec.deployment.metadata.annotations, "isrollback", "true");

      applicationUpdate(this.data, { vendor: this.vendor, region: this.region, cluster: this.cluster }).then(
        response => {
          this.loadingData[verison] = false;
          if (response.code === 0) {
            this.rollbackVisible = false;
            this.$emit("update");
            this.$notify({
              title: "Success",
              message: "Success",
              type: "success"
            });
          }
        }
      );
    }
  },

  computed: {
    type() {
      return this.$route.params.type;
    }
  }
};
</script>
