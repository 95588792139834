<template>
  <div>
    <div @click="deleteResource">
      {{ $t("handle.delete") }}
    </div>
  </div>
</template>

<script>
import { applicationDelete } from "api/deployment";
import { configMapDelete } from "api/configmap";
import { secretDelete } from "api/secret";
import { ingressDelete } from "api/ingress";
import { namespaceDelete } from "api/namespace";
import { pvcDelete, pvDelete } from "api/pv";
import { serviceDelete } from "api/service";
import { storageClassDelete } from "api/storageClass";

export default {
  props: {
    type: {
      //Application
      //ConfigMap
      //Secret
      //Ingress
      //Namespace
      //PV
      //Service
      //StorageClass
      type: String
    },
    name: {
      type: String
    },
    namespace: {
      type: String
    },
    vendor: {
      type: String
    },
    cluster: {
      type: String
    },
    region: {
      type: String
    }
  },

  methods: {
    deleteResource() {
      this.$prompt(this.$t("delete", [this.name]) + this.$t("confirm"), this.$t("prompt"), {
        dangerouslyUseHTMLString: true,
        inputValidator: val => {
          if (val != this.name) return this.$t("rules.pattern");
        }
      })
        .then(() => {
          let action;

          switch (this.type) {
            case "ConfigMap":
              action = configMapDelete;
              break;

            case "Secret":
              action = secretDelete;
              break;

            case "Ingress":
              action = ingressDelete;
              break;

            case "Namespace":
              action = namespaceDelete;
              break;

            case "PersistentVolume":
              action = pvDelete;
              break;

            case "PersistentVolumeClaims":
              action = pvcDelete;
              break;

            case "Service":
              action = serviceDelete;
              break;

            case "StorageClass":
              action = storageClassDelete;
              break;

            default:
              action = applicationDelete;
          }

          action(
            {
              ...{ vendor: this.vendor, region: this.region, cluster: this.cluster, namespace: this.namespace },
              name: this.name
            },
            {
              type: this.type
            }
          ).then(response => {
            if (response.code === 0) {
              this.$emit("delete");
            }
          });
        })
        .catch(() => {});
    }
  }
};
</script>
