<template>
  <div>
    <el-table :data="containers" v-if="layout == 'table'">
      <el-table-column :label="$t('name')" prop="name" sortable min-width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="table-name-info">
            <svg-icon icon-class="Container"></svg-icon>
            <div class="content">
              <div class="title">{{ scope.row.name }}</div>
              <span class="desc" :title="scope.row.image">
                <i class="el-icon-document-copy" v-clipboard:copy="scope.row.image"></i>
                {{ scope.row.image }}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('restartTimes')">
        <template slot-scope="scope">
          {{ mapStatus(scope.row.name) ? mapStatus(scope.row.name).restartCount : "-" }}
        </template>
      </el-table-column>

      <el-table-column :label="$t('port')">
        <template slot-scope="scope">
          {{ returnNodePort(scope.row.ports) }}
        </template>
      </el-table-column>

      <el-table-column :label="$t('logs')">
        <template slot-scope="scope">
          <a @click.prevent="toXtrem('log', scope.row.name)">{{ $t("handle.toLogs") }}</a>
        </template>
      </el-table-column>

      <el-table-column :label="$t('terminal')">
        <template slot-scope="scope">
          <a @click.prevent="toXtrem('shell', scope.row.name)">{{ $t("handle.toTerminal") }}</a>
        </template>
      </el-table-column>
    </el-table>

    <div v-else>
      <div class="table-name-info" v-for="item in containers" :key="item.name" style="margin-bottom: 4px;">
        <div class="content">
          <div class="title container-wrapper">
            <div class="container-name">
              {{ item.name }}
            </div>

            <el-tooltip effect="dark" :content="$t('handle.toLogs')" placement="bottom">
              <svg-icon icon-class="logs" @click.native="toXtrem('log', item.name)"></svg-icon>
            </el-tooltip>

            <el-tooltip effect="dark" :content="$t('handle.toTerminal')" placement="bottom">
              <svg-icon icon-class="terminal" @click.native="toXtrem('shell', item.name)"></svg-icon>
            </el-tooltip>

            <el-tooltip effect="dark" placement="bottom" v-if="showErrMessage(item.name)">
              <div slot="content" style="max-width: 400px; line-height: 22px;">
                {{ mapErrMessage(item.name) }}
              </div>
              <svg-icon icon-class="warn"></svg-icon>
            </el-tooltip>
          </div>

          <span class="desc">
            {{ $t("restartTimes") }}

            <span class="title">
              {{ renderRestartCount(item.name) }}
            </span>
          </span>

          <span class="desc" :title="item.image">
            <i class="el-icon-document-copy" v-clipboard:copy="item.image"></i>
            {{ item.image }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    containers: {
      type: Array
    },
    status: {
      type: Array
    },
    pod: {
      type: String
    },
    vendor: {
      type: String
    },
    region: {
      type: String
    },
    cluster: {
      type: String
    },
    namespace: {
      type: String
    },
    layout: {
      type: String,
      default: "table"
    }
  },

  methods: {
    returnNodePort(arr) {
      if (arr) {
        let str = "";
        arr.forEach(item => (str += `${item.containerPort}/${item.protocol} `));
        return str;
      } else {
        return "-";
      }
    },

    renderRestartCount(name) {
      if (this.status && this.status.length > 0) {
        let restartCount = "-";

        this.status.forEach(item => {
          if (item.name === name) {
            restartCount = item.restartCount;
          }
        });

        return restartCount;
      }

      return "-";
    },

    toXtrem(type, containerName) {
      let routeUrl;
      if (type == "log") {
        routeUrl = this.$router.resolve({
          path: `/logs/${this.pod}/${containerName}`,
          query: {
            ...{
              vendor: this.vendor,
              region: this.region,
              cluster: this.cluster,
              namespace: this.namespace
            }
          }
        });
      }

      if (type == "shell") {
        routeUrl = this.$router.resolve({
          path: `/shell/${this.pod}/${containerName}`,
          query: {
            ...{
              vendor: this.vendor,
              region: this.region,
              cluster: this.cluster,
              namespace: this.namespace
            }
          }
        });
      }

      window.open(routeUrl.href, "_blank");
    },

    showErrMessage(name) {
      if (this.mapStatus(name)) {
        let state = this.mapStatus(name).state;

        if ("running" in state) return false;
        if ("terminated" in state && state["terminated"].exitCode === 0) return false;

        return true;
      }

      return false;
    },

    mapErrMessage(name) {
      if (this.mapStatus(name)) {
        let state = this.mapStatus(name).state;
        return state;
      }

      return "";
    },

    mapStatus(name) {
      if (this.status) {
        for (let item of this.status) {
          if (item.name == name) return item;
        }
      }

      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";
.container-wrapper {
  white-space: nowrap;
  line-height: 14px;

  .container-name {
    @include text-overflow();
    max-width: calc(100% - 50px);
    display: inline-block;
  }

  .svg-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    padding-left: 5px;
    margin-right: 0;
  }
}
</style>
