import i18n from "@/lang";
import moment from "moment";
import { get } from "lodash";

export function mapInfo(type, data) {
  let info = [
    // {
    //   name: i18n.t("name"),
    //   value: get(data, "metadata.name", "-")
    // },
    {
      name: i18n.t("createTime"),
      value: get(data, "metadata.creationTimestamp")
        ? moment(get(data, "metadata.creationTimestamp")).format("YYYY-MM-DD HH:mm:ss")
        : "-"
    }
  ];

  switch (type) {
    case "Deployment":
      info.push(
        ...[
          {
            name: i18n.t("image"),
            value: get(data, "spec.deployment.spec.template.spec.containers[0].image", "-")
          },

          {
            name: i18n.t("replicas"),
            value: get(data, "status.deployment.replicas", "-")
          },

          {
            name: i18n.t("updatedReplicas"),
            value: get(data, "status.deployment.updatedReplicas", "-")
          },

          {
            name: i18n.t("readyReplicas"),
            value: get(data, "status.deployment.readyReplicas", "-")
          },

          {
            name: i18n.t("availableReplicas"),
            value: get(data, "status.deployment.availableReplicas", "-")
          },

          {
            name: i18n.t("unavailableReplicas"),
            value: get(data, "status.deployment.unavailableReplicas", "-")
          },

          {
            name: i18n.t("strategy"),
            value: get(data, "spec.deployment.spec.strategy.type", "-")
          }
        ]
      );

      break;

    case "StatefulSet":
      info.push(
        ...[
          {
            name: i18n.t("replicas"),
            value: get(data, "status.statefulset.replicas", "-")
          },

          {
            name: i18n.t("readyReplicas"),
            value: get(data, "status.statefulset.readyReplicas", "-")
          },

          {
            name: i18n.t("currentReplicas"),
            value: get(data, "status.statefulset.currentReplicas", "-")
          },

          {
            name: i18n.t("updatedReplicas"),
            value: get(data, "status.statefulset.updatedReplicas", "-")
          },

          {
            name: i18n.t("currentRevision"),
            value: get(data, "status.statefulset.currentRevision", "-")
          },

          {
            name: i18n.t("updateRevision"),
            value: get(data, "status.statefulset.updateRevision", "-")
          },

          {
            name: i18n.t("collisionCount"),
            value: get(data, "status.statefulset.collisionCount", "-")
          },

          {
            name: i18n.t("podManagementPolicy"),
            value: get(data, "spec.statefulset.spec.podManagementPolicy", "-")
          }
        ]
      );
      break;

    case "CronJob":
      info.push(
        ...[
          {
            name: i18n.t("schedule"),
            value: get(data, "spec.cronjob.spec.schedule", "-")
          },

          {
            name: i18n.t("successfulJobsHistoryLimit"),
            value: get(data, "spec.cronjob.spec.successfulJobsHistoryLimit", "-")
          },
          {
            name: i18n.t("failedJobsHistoryLimit"),
            value: get(data, "spec.cronjob.spec.failedJobsHistoryLimit", "-")
          },

          {
            name: i18n.t("status"),
            value: get(data, "spec.cronjob.spec.suspend") === true ? "Suspended" : "Scheduled"
          },

          {
            name: i18n.t("lastSchedule"),
            value: get(data, "status.cronjob.lastScheduleTime")
              ? moment(get(data, "status.cronjob.lastScheduleTime")).format("YYYY-MM-DD HH:mm:ss")
              : "-"
          }
        ]
      );
      break;

    case "DaemonSet":
      info.push(
        ...[
          {
            name: i18n.t("strategy"),
            value: get(data, "spec.daemonset.spec.updateStrategy.type", "-")
          },

          {
            name: i18n.t("currentNumberScheduled"),
            value: get(data, "status.daemonset.currentNumberScheduled", "-")
          },

          {
            name: i18n.t("numberMisscheduled"),
            value: get(data, "status.daemonset.numberMisscheduled", "-")
          },

          {
            name: i18n.t("desiredNumberScheduled"),
            value: get(data, "status.daemonset.desiredNumberScheduled", "-")
          },

          {
            name: i18n.t("numberReady"),
            value: get(data, "status.daemonset.numberReady", "-")
          },

          {
            name: i18n.t("observedGeneration"),
            value: get(data, "status.daemonset.observedGeneration", "-")
          },

          {
            name: i18n.t("updatedNumberScheduled"),
            value: get(data, "status.daemonset.updatedNumberScheduled", "-")
          },

          {
            name: i18n.t("numberAvailable"),
            value: get(data, "status.daemonset.numberAvailable", "-")
          },

          {
            name: i18n.t("numberUnavailable"),
            value: get(data, "status.daemonset.numberUnavailable", "-")
          }
        ]
      );
      break;

    case "Cluster":
      info.push(
        ...[
          {
            name: i18n.t("kubeletVersion"),
            value: get(data, "nodeInfo.kubeletVersion", "-")
          },
          {
            name: i18n.t("kubeProxyVersion"),
            value: get(data, "nodeInfo.kubeProxyVersion", "-")
          },
          {
            name: i18n.t("kernelVersion"),
            value: get(data, "nodeInfo.kernelVersion", "-")
          },
          {
            name: i18n.t("osImage"),
            value: get(data, "nodeInfo.osImage", "-")
          },
          {
            name: i18n.t("containerRuntimeVersion"),
            value: get(data, "nodeInfo.containerRuntimeVersion", "-")
          },
          {
            name: i18n.t("architecture"),
            value: get(data, "nodeInfo.architecture", "-")
          }
        ]
      );
      break;

    case "Namespace":
      info.push(
        ...[
          {
            name: i18n.t("status"),
            value: get(data, "status.namespace.phase", "-")
          }
        ]
      );
      break;

    case "Node":
      info.push(
        ...[
          {
            name: i18n.t("kernelVersion"),
            value: get(data, "status.nodeInfo.kernelVersion", "-")
          },
          {
            name: i18n.t("osImage"),
            value: get(data, "status.nodeInfo.osImage", "-")
          },
          {
            name: i18n.t("containerRuntimeVersion"),
            value: get(data, "status.nodeInfo.containerRuntimeVersion", "-")
          },
          {
            name: i18n.t("kubeletVersion"),
            value: get(data, "status.nodeInfo.kubeletVersion", "-")
          },
          {
            name: i18n.t("kubeProxyVersion"),
            value: get(data, "status.nodeInfo.kubeProxyVersion", "-")
          },
          {
            name: i18n.t("architecture"),
            value: get(data, "status.nodeInfo.architecture", "-")
          }
        ]
      );
      break;

    case "Pod":
      info.push(
        ...[
          {
            name: i18n.t("image"),
            value: get(data, "spec.pod.spec.containers[0].image", "-")
          },

          {
            name: i18n.t("podIP"),
            value: get(data, "status.pod.podIP", "-")
          },

          {
            name: i18n.t("hostIP"),
            value: get(data, "status.pod.hostIP", "-")
          },

          {
            name: i18n.t("nodeName"),
            value: get(data, "spec.pod.spec.nodeName", "-")
          }
        ]
      );
      break;

    case "PersistentVolume":
      info.push(
        ...[
          {
            name: i18n.t("accessModes"),
            value: get(data, "spec.accessModes[0]", "-")
          },

          {
            name: i18n.t("storage"),
            value: get(data, "spec.capacity.storage", "-")
          },

          {
            name: i18n.t("storageClassName"),
            value: get(data, "spec.storageClassName", "-")
          },

          {
            name: i18n.t("volumeMode"),
            value: get(data, "spec.volumeMode", "-")
          },

          {
            name: i18n.t("persistentVolumeReclaimPolicy"),
            value: get(data, "spec.persistentVolumeReclaimPolicy", "-")
          },

          {
            name: i18n.t("status"),
            value: get(data, "status.phase", "-")
          }
        ]
      );
      break;

    case "PersistentVolumeClaims":
      info.push(
        ...[
          {
            name: i18n.t("volumeName"),
            value: get(data, "spec.volumeName", "-")
          },

          {
            name: i18n.t("storage"),
            value: get(data, "spec.resources.requests.storage", "-")
          },

          {
            name: i18n.t("storageClassName"),
            value: get(data, "spec.storageClassName", "-")
          },

          {
            name: i18n.t("accessModes"),
            value: get(data, "spec.accessModes[0]", "-")
          },

          {
            name: i18n.t("volumeMode"),
            value: get(data, "spec.volumeMode", "-")
          },

          {
            name: i18n.t("status"),
            value: get(data, "status.phase", "-")
          }
        ]
      );
      break;

    case "Service":
      info.push(
        ...[
          {
            name: i18n.t("serviceType"),
            value: formatType(data.spec)
          },

          {
            name: i18n.t("clusterIP"),
            value: get(data, "spec.clusterIP", "-")
          },

          {
            name: i18n.t("LoadBalancer") + "(LoadBalancer)",
            value: get(data, "status.loadBalancer.ingress[0].hostname", "-")
          },

          {
            name: i18n.t("LoadBalancer") + "(NodePort)",
            value: get(data, "metadata.annotations.ingress-host", "-")
          }
        ]
      );
      break;
  }

  return info;
}

function formatType(spec) {
  if ("clusterIP" in spec && spec.clusterIP == "None" && spec.type == "ClusterIP") {
    return "Headless";
  } else {
    return spec.type;
  }
}

export function mapConditions(type, data) {
  let typeMin = type.toLowerCase();
  return get(data, `status.${typeMin}.conditions`, []);
}
