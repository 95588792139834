<template>
  <div v-loading="loading">
    <el-table :data="list" :max-height="maxHeight">
      <el-table-column :label="$t('nodeName')" prop="name" sortable min-width="150">
        <template slot-scope="scope">
          <div class="table-name-info">
            <svg-icon icon-class="Node"></svg-icon>
            <div class="content">
              <a @click.prevent="toNode(scope.row.name)">
                {{ scope.row.name }}
              </a>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('status')">
        <template slot-scope="scope">
          <div class="status success" v-if="mapStatus('node_ready_status', scope.row.metrics)">Running</div>
          <div class="status danger" v-else>NotReady</div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('cpu')">
        <template slot-scope="scope">
          <div class="usage-info">
            <div class="title">{{ mapPercent("node_cpu_usage_total", "node_cpu_total", scope.row.metrics) }}%</div>
            <div class="desc">
              {{ mapValue("node_cpu_usage_total", scope.row.metrics) }} /
              {{ mapValue("node_cpu_total", scope.row.metrics) }}
              {{ mapUnit("node_cpu_total", scope.row.metrics) }}
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('memory')">
        <template slot-scope="scope">
          <div class="usage-info">
            <div class="title">
              {{ mapPercent("node_memory_usage_total", "node_memory_total", scope.row.metrics) }}%
            </div>
            <div class="desc">
              {{ mapValue("node_memory_usage_total", scope.row.metrics) }} /
              {{ mapValue("node_memory_total", scope.row.metrics) }}
              {{ mapUnit("node_memory_total", scope.row.metrics) }}
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('pod')">
        <template slot-scope="scope">
          <div class="usage-info">
            <div class="title">{{ mapPercent("node_pod_usage_total", "node_pod_total", scope.row.metrics) }}%</div>
            <div class="desc">
              {{ mapValue("node_pod_usage_total", scope.row.metrics) }} /
              {{ mapValue("node_pod_total", scope.row.metrics) }}
              {{ mapUnit("node_pod_total", scope.row.metrics) }}
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      layout="prev, pager, next, sizes, total"
      :total="total"
      :page-size="currentPageSize"
      :page-sizes="[5, 10, 15, 20]"
      :current-page="currentPage"
      @current-change="currentChange"
      @size-change="sizeChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { find, isObject } from "lodash";
import { nodeInfra } from "api/monitor";
import moment from "moment";
export default {
  props: {
    vendor: {
      type: String
    },
    cluster: {
      type: String
    },
    region: {
      type: String
    },
    maxHeight: {
      type: [String, Number]
    }
  },
  data() {
    return {
      list: [],
      total: 0,
      loading: false,
      currentPage: 1,
      currentPageSize: 10,
      chartTitle: {}
    };
  },
  methods: {
    moment,
    findByKey(target, metrics) {
      return find(metrics, { metricName: target }) ? find(metrics, { metricName: target }) : undefined;
    },
    mapValue(target, metrics) {
      let value = "";
      isObject(this.findByKey(target, metrics)) ? (value = this.findByKey(target, metrics).value) : (value = "-");
      return value;
    },
    mapUnit(target, metrics) {
      let unit = "";
      isObject(this.findByKey(target, metrics)) ? (unit = this.findByKey(target, metrics).unit) : (unit = "");
      return unit;
    },
    mapPercent(usageTarget, totalTarget, metrics) {
      let usage = find(metrics, { metricName: usageTarget })
        ? find(metrics, { metricName: usageTarget }).value
        : undefined;
      let total = find(metrics, { metricName: totalTarget })
        ? find(metrics, { metricName: totalTarget }).value
        : undefined;
      if (usage && total) return Number(((usage / total) * 100).toFixed(2));
      return "-";
    },
    mapStatus(target, metrics) {
      return this.findByKey(target, metrics).value === 1 ? true : false;
    },

    currentChange(page) {
      this.currentPage = page;
      this.getList(page);
    },

    sizeChange(size) {
      this.currentPageSize = size;
      this.getList(1, size);
    },

    getList(page = 1, pageSize = this.currentPageSize) {
      this.loading = true;
      this.currentPage = page;
      nodeInfra(
        {
          cluster: this.cluster,
          region: this.region,
          vendor: this.vendor
        },
        { page, pageSize }
      ).then(response => {
        if (response.code === 0) {
          this.loading = false;
          this.list = response.data.items;
          this.total = response.data.total;
        }
      });
    },
    toNode(name) {
      this.$router.push({
        path: `/detail/Node/${name}`,
        query: { vendor: this.vendor, region: this.region, cluster: this.cluster }
      });
    }
  },
  mounted() {
    this.getList();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";
.usage-info {
  .title {
    font-weight: bolder;
    font-size: 14px;
    line-height: 22px;
  }
  .desc {
    font-size: 12px;
    color: $color-sub;
    line-height: 1.5;
  }
}
</style>
