<template>
  <div>
    <div class="desc">
      {{ formatProgressText() }}
    </div>
    <el-progress :percentage="formatProgressPercent()" :show-text="false" />
  </div>
</template>

<script>
import { isEmpty } from "lodash";

export default {
  props: {
    type: String,
    resources: Object
  },

  methods: {
    formatProgressPercent() {
      let type = this.type;
      let resources = this.resources;

      if (isEmpty(resources)) {
        return 0;
      } else {
        let { requests, limits } = resources;

        if (isEmpty(limits)) {
          return 0;
        } else if (isEmpty(requests)) {
          return 0;
        } else {
          let limit = 0;
          let request = 0;
          if (limits && limits[type]) limit = limits[type];
          if (requests && requests[type]) request = requests[type];

          return (this.formatResourceUnit(request) / this.formatResourceUnit(limit)) * 100;
        }
      }
    },

    formatProgressText() {
      let type = this.type;
      let resources = this.resources;

      if (isEmpty(resources)) {
        return "- / -";
      } else {
        let { requests, limits } = resources;

        let limit = "";
        let request = "";
        if (limits && limits[type]) {
          limit = limits[type];
          if (type == "cpu" && !limit.endsWith("m")) limit += "Core";
        } else {
          limit = "-";
        }

        if (requests && requests[type]) {
          request = requests[type];
          if (type == "cpu" && !request.endsWith("m")) request += "Core";
        } else {
          request = "-";
        }

        return `${request} / ${limit}`;
      }
    },

    formatResourceUnit(value) {
      let result = 0;

      if (value) {
        if (value.endsWith("m")) {
          result = parseInt(value);
        } else if (value.endsWith("Mi")) {
          result = parseInt(value);
        } else if (value.endsWith("M")) {
          result = parseInt(value);
        } else if (value.endsWith("G")) {
          result = parseInt(value) * 1024;
        } else {
          result = parseInt(value) * 1000;
        }
      }

      return result;
    }
  }
};
</script>

<style></style>
