import http from "../http";

export const ingressDetail = payload => {
  return http({
    method: "get",
    url: `/crd.kubestar.io/vendors/${payload.vendor}/regions/${payload.region}/clusters/${payload.cluster}/namespaces/${payload.namespace}/ingresses/${payload.ingress}`
  });
};

export const ingressDelete = data => {
  return http({
    method: "delete",
    url: `/k8s.kubestar.io/vendors/${data.vendor}/regions/${data.region}/clusters/${data.cluster}/namespaces/${data.namespace}/ingresses/${data.name}`
  });
};

export const ingressList = params => {
  return http({
    method: "get",
    params,
    url: `/crd.kubestar.io/ingresses`
  });
};

export const ingressSubmit = (data, headers) => {
  return http({
    method: "post",
    data,
    url: `/k8s.kubestar.io/namespaces/${data.metadata.namespace}/ingresses`,
    headers
  });
};

export const ingressUpdate = (data, headers) => {
  return http({
    method: "put",
    data,
    url: `/k8s.kubestar.io/namespaces/${data.metadata.namespace}/ingresses`,
    headers
  });
};
